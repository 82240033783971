@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@media (max-width: 992px) {
    .bannerTwoContainer {
        width: 100%;
        height: fit-content;
        margin: 150px 0 0 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    
    .bannerTwoContainer .card {
        width: 85%;
        height: fit-content;
        border: none;
        border-radius: 10px;
        position: relative;
        margin: auto;
        margin-bottom: 100px;
    }

    .bannerTwoContainer .card .imgContainer {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 25px;
        margin-top: -80px;
        margin-left: 50px;
        margin-bottom: 25px;
        box-shadow: 0px -4px 20px 5px #0000001f
    }

    .bannerTwoContainer .card .card-body {
        color: white;
        font-family: 'OpenSans';
        padding: 5px 2px 40px 2px;
    }

    .bannerTwoContainer .cardTitle {
        font-size: 1.7em;
        position: relative;
    }

    .bannerTwoContainer .cardTitle::before {
        width: 50px;
        height: 2px;
        background-color: white;
        position: absolute;
        bottom:-15px;
        left: 40%;
        content: '';
    }
    .bannerTwoContainer .card .card-body .card-text {
        padding: 5px;
        margin: 25px 0 0 0;
        font-weight: 600;
        font-size: .9em;
    }

    .bannerTwoContainer .card:nth-child(1) {
        background-color: rgb(246,171,0);
    }
    
    .bannerTwoContainer .card:nth-child(2) {
        background-color: rgb(255,0,0);
    }

    .bannerTwoContainer .card:nth-child(3) {
        background-color: rgb(0,58,106);
    }

    .bannerTwoContainer .card:nth-child(4) {
        background-color: rgb(7,179,215);
    }
    .bannerTwoContainer .card img {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 992px) { 
    .bannerTwoContainer {
        width: 100%;
        height: fit-content;
        margin: 50px 0 0 0;
        padding: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .bannerTwoContainer .card {
        width: 250px;
        height: fit-content;
        border: none;
        border-radius: 10px;
        position: relative;
    }

    .bannerTwoContainer .card .imgContainer {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 25px;
        margin-top: -80px;
        margin-left: 50px;
        margin-bottom: 25px;
        box-shadow: 0px -4px 20px 5px #0000001f
    }

    .bannerTwoContainer .card .card-body {
        color: white;
        font-family: 'OpenSans';
        padding: 5px 2px 40px 2px;
    }

    .bannerTwoContainer .cardTitle {
        font-size: 1.7em;
        position: relative;
    }

    .bannerTwoContainer .cardTitle::before {
        width: 50px;
        height: 2px;
        background-color: white;
        position: absolute;
        bottom:-15px;
        left: 40%;
        content: '';
    }
    .bannerTwoContainer .card .card-body .card-text {
        padding: 5px;
        margin: 25px 0 0 0;
        font-weight: 600;
        font-size: .9em;
    }

    .bannerTwoContainer .card:nth-child(1) {
        background-color: rgb(246,171,0);
    }
    
    .bannerTwoContainer .card:nth-child(2) {
        background-color: rgb(255,0,0);
    }

    .bannerTwoContainer .card:nth-child(3) {
        background-color: rgb(0,58,106);
    }

    .bannerTwoContainer .card:nth-child(4) {
        background-color: rgb(7,179,215);
    }
    .bannerTwoContainer .card img {
        width: 100px;
        height: 100px;
    }
}
