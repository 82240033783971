@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}


@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@media (min-width: 992px) {
    /*desktop design*/
    .contactUsContainer {
        width: 100%;
        height: 500px;
        background: url('./assets/DSC_6767.JPG') no-repeat;
        background-size: cover;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .contactUsContainer::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
    }
    .contactUsContainer h1 {
        width: 65%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: rgb(116,188,65);
        text-decoration: 0 5px 5px 0 black;
        font-weight: bolder;
        z-index: 2;
        margin: 50px auto;
        position: relative;
        word-wrap: break-word; 
    }
    .contactUsContainer p {
        color: black;
        z-index: 2;
        width: 70%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 1em;
        text-decoration: 0 5px 5px 0 black;
        font-weight: 400;
        margin: auto;
    }
    .contactUsContainer button {
        z-index: 2;
        width: 250px;
        border-radius: 50px;
        margin: 50px auto;
        background-color: rgb(116,188,65);
        padding: 10px;
        font-family: 'OpenSans';
        font-size: 1.5em;
        font-weight: bolder;
        color: white;
        transition: all .3s ease-in;
    }
    .contactUsContainer button:hover {
        box-shadow: 0 4px 4px 0 black;
    }
    
}

@media (max-width: 992px) {
    /*mobile design*/
    .contactUsContainer {
        width: 100%;
        height: fit-content;
        background: url('./assets/DSC_6767.JPG') no-repeat;
        background-size: cover;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .contactUsContainer::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
    }
    .contactUsContainer h1 {
        width: 65%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: rgb(116,188,65);
        text-decoration: 0 5px 5px 0 black;
        font-weight: bolder;
        z-index: 2;
        margin: 50px auto;
        position: relative;
        word-wrap: break-word; 
    }
    .contactUsContainer p {
        color: black;
        z-index: 2;
        width: 70%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 1em;
        text-decoration: 0 5px 5px 0 black;
        font-weight: 400;
        margin: auto;
    }
    .contactUsContainer button {
        z-index: 2;
        width: 250px;
        border-radius: 50px;
        margin: 50px auto;
        background-color: rgb(116,188,65);
        padding: 10px;
        font-family: 'OpenSans';
        font-size: 1.5em;
        font-weight: bolder;
        color: white;
        transition: all .3s ease-in;
    }
    .contactUsContainer button:hover {
        box-shadow: 0 4px 4px 0 black;
    }
    
}