@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@media (max-width: 992px) {
 /*mobile size*/
/*desktop size*/
.bannerThreeContainer {
    width: 100%;
    height: fit-content;
    margin: 50px 0 100px 0;
    padding:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bannerThreeContainer .leftContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.bannerThreeContainer .leftContainer .imgOne {
    width: 90%;
    height: 600px;
    z-index: 1;
    object-fit: cover;
}

.bannerThreeContainer .leftContainer .imgTwo {
    width: 250px;
    height: 250px;
    border: 10px solid rgba(255, 255, 255, 0.672);
    z-index: 2;
    position: absolute;
    bottom: 100px;
    left: 10px;
    object-fit: cover;
}

.bannerThreeContainer .leftContainer .imgThree {
    width: 350px;
    height: 250px;
    position: absolute;
    bottom: -5px;
    right: 45px;
    background-color: rgba(116, 188, 65, 0.706);
}



.bannerThreeContainer .rightContainer {
    width: 100%;
    height: fit-content;
    padding: 10px 30px 30px 10px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    overflow: hidden;
}

.bannerThreeContainer .rightContainer .title {
    width: 99%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
}

.bannerThreeContainer .rightContainer .title h1 {
    font-family: 'OpenSans';
    font-size: 3em;
    font-weight: bolder;
    color: black;
    text-align: left;
}

.bannerThreeContainer .rightContainer .title p {
    font-family: 'OpenSans';
    font-size: 1em;
    font-weight: 500;
    color: #333;
    text-align: left;
    margin: 8px;
}

.bannerThreeContainer .rightContainer .body {
    width: 99%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-self: space-evenly;
}

.bannerThreeContainer .rightContainer .body .cards {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 50px;
}

.bannerThreeContainer .rightContainer .body .cards .cardImgContainer {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position:absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
}

.bannerThreeContainer .rightContainer .body .cards:nth-child(1) .cardImgContainer {
    background-color: rgb(7,179,215);
}
.bannerThreeContainer .rightContainer .body .cards:nth-child(2) .cardImgContainer {
    background-color: rgb(0, 191, 80);
}

.bannerThreeContainer .rightContainer .body .cards:nth-child(3) .cardImgContainer {
    background-color: rgb(234, 0, 56);
}

.bannerThreeContainer .rightContainer .body .cards .cardImgContainer img {
    width: 70px;
    height: 70px;
}

.bannerThreeContainer .rightContainer .body .cards .cardContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 120px;
}

.bannerThreeContainer .rightContainer .body .cards .cardContentContainer h3 {
    text-align: left;
    margin-bottom: 8px;
    font-family: 'OpenSans';
    font-family: 1.1em;
}
.bannerThreeContainer .rightContainer .body .cards .cardContentContainer p {
    text-align: left;
    font-family: 'OpenSans';
    color: #333;
}
}

@media (min-width: 992px) {
    /*desktop size*/
    .bannerThreeContainer {
        width: 100%;
        height: fit-content;
        margin: 50px 0 100px 0;
        padding:0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .bannerThreeContainer .leftContainer {
        width: 50%;
        height: fit-content;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    .bannerThreeContainer .leftContainer .imgOne {
        width: 100%;
        height: 600px;
        z-index: 1;
        object-fit: cover;
    }

    .bannerThreeContainer .leftContainer .imgTwo {
        width: 250px;
        height: 250px;
        border: 10px solid rgba(255, 255, 255, 0.672);
        z-index: 2;
        position: absolute;
        bottom: 100px;
        left: -50px;
        object-fit: cover;
    }

    .bannerThreeContainer .leftContainer .imgThree {
        width: 350px;
        height: 250px;
        position: absolute;
        bottom: -20px;
        right: -20px;
        background-color: rgba(116, 188, 65, 0.706);
    }



    .bannerThreeContainer .rightContainer {
        width: 35%;
        height: fit-content;
        margin-left: 50px;
        padding: 10px 0 30px 0;
        display: flex;
        flex-direction: column;
        justify-self: flex-start;
        overflow: hidden;
    }

    .bannerThreeContainer .rightContainer .title {
        width: 99%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-self: flex-start;
    }

    .bannerThreeContainer .rightContainer .title h1 {
        font-family: 'OpenSans';
        font-size: 3em;
        font-weight: bolder;
        color: black;
        text-align: left;
    }

    .bannerThreeContainer .rightContainer .title p {
        font-family: 'OpenSans';
        font-size: 1em;
        font-weight: 500;
        color: #333;
        text-align: left;
        margin: 8px;
    }

    .bannerThreeContainer .rightContainer .body {
        width: 99%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-self: space-evenly;
    }

    .bannerThreeContainer .rightContainer .body .cards {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        position: relative;
        margin-top: 50px;
    }

    .bannerThreeContainer .rightContainer .body .cards .cardImgContainer {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position:absolute;
        top: 0;
        left: 0;
        padding-top: 15px;
    }

    .bannerThreeContainer .rightContainer .body .cards:nth-child(1) .cardImgContainer {
        background-color: rgb(7,179,215);
    }
    .bannerThreeContainer .rightContainer .body .cards:nth-child(2) .cardImgContainer {
        background-color: rgb(0, 191, 80);
    }

    .bannerThreeContainer .rightContainer .body .cards:nth-child(3) .cardImgContainer {
        background-color: rgb(234, 0, 56);
    }

    .bannerThreeContainer .rightContainer .body .cards .cardImgContainer img {
        width: 70px;
        height: 70px;
    }

    .bannerThreeContainer .rightContainer .body .cards .cardContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 120px;
    }

    .bannerThreeContainer .rightContainer .body .cards .cardContentContainer h3 {
        text-align: left;
        margin-bottom: 8px;
        font-family: 'OpenSans';
        font-family: 1.1em;
    }
    .bannerThreeContainer .rightContainer .body .cards .cardContentContainer p {
        text-align: left;
        font-family: 'OpenSans';
        color: #333;
    }
}