
    .step-1 {
      width: 85%;
      height: fit-content;
      position: relative;
    }
    .inputControl {
      width: 100%;
      height: fit-content;
      margin: 0 0 10px 20px;
    }
    .inputStyles {
      width: 90%;
      border: 1px solid black;
      cursor: pointer;
      font-size: 1.2em;
      font-weight: 400;
      color: hsl(213, 96%, 18%);
      padding: 5px;
      border-radius: 10px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .inputStyles:hover {
      border: 1px solid hsl(243, 100%, 62%);
    }
    .inputStyles:focus {
      outline: none;
      border: 1px solid hsl(243, 100%, 62%);
    }
    /* .inputControl:nth-child(1) input {
      border: 1px solid black;
    }
    .inputControl:nth-child(3) input {
      border: 1px solid darkred;
    } */
    .inputControl label {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: black;
    }
    .inputControl label span:nth-child(2) {
      color: darkred;
    }
    .stepTitle {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 0 0 20px;
      position: relative;
    }
    .stepTitle h1 {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 400;
      color: hsl(213, 96%, 18%);

    }
    .stepTitle p {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 400;
      color: hsl(231, 11%, 63%);
      margin-top: -10px;
    }
    .buttonContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .form-button {
      background-color: hsl(213, 96%, 18%);
      font-size: 16px;
      color: white;
      width: 110px;
      height: 44px;
      border-radius: 7px;
      border: none;
      cursor: pointer;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-size: 15px;
      margin-right: -40px;
      margin-top: 20px;
    }
    .form-button:hover {
      background-color: hsl(213, 50%, 25%);
    }

    .navigation {
      background: url("./assets/images/bg-sidebar-desktop.svg") no-repeat;
      background-size: cover;
      width: fit-content;
      height: 500px;
      padding: 10px;
    }
    .nav-item-forms {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 30px;
      cursor: pointer;
    }
    .nav-item-count{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid hsl(206, 94%, 87%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-family: Arial, Helvetica, sans-serif;
      background-color: transparent;
      color: white;
      margin: 10px;
    }
    .active {
      color: black;
      background-color: hsl(206, 94%, 87%);
    }
    .nav-item-name {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .nav-item-name p:nth-child(1) {
      font-size: 15px;
      font-weight: lighter;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: hsl(229, 24%, 87%);
      text-transform: uppercase;
    }
    .nav-item-name p:nth-child(2) {
      font-size: 15px;
      font-weight: 400;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: white;
      text-transform: uppercase;
    }
    .forms {
      width: 70%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: white;
      margin: 150px auto;
      padding: 10px;
      border-radius: 10px;
      transition: all .3s ease-out;
    }

    .forms:hover {
        box-shadow: 0 0 10px 3px black;
    }