@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}




@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}



@media (min-width: 992px) {
    #footerContainer-top-id {
        width: 100vw;
        display: flex;
        flex-direction: column;
        /* justify-content: space-around;
        padding: 50px; */
    }
}

.footerContainer {
    width: 100vw;
    height: fit-content;
}

.footerContainer img {
    width: 35px;
    height: 35px;
}

#footerContainer-top-id {
    background-color: rgb(116,188,65);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

#footerContainer-top-id div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footerContainer-top-id div:nth-child(1) p {
    font-family: 'OpenSans';
    color: white;
}

#footerContainer-top-id div:nth-child(1) p:nth-child(1) {
    font-size: 3em;
    font-weight: bold;
}

#footerContainer-top-id form {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
}

#footerContainer-top-id form div:nth-child(1) {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#footerContainer-top-id form div:nth-child(1) label {
    width: 50px;
    height: 100%;
    border: none;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#footerContainer-top-id form div:nth-child(1) input {
    width: 250px;
    height: 100%;
    font-family: 'OpenSans', sans-serif;
    padding: 0;
    font-size: 1.2em;
    border: none;
    text-align: center;
}

#footerContainer-top-id form div:nth-child(2) {
    width: 250px;
    height: 45px;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
}

#footerContainer-top-id form div:nth-child(2) input {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    font-family: 'OpenSans';
    border: none;
    font-size: 1.5em;
    transition: all .3s ease-in-out;
    margin-top: 20px;
} 

#footerContainer-top-id form div:nth-child(2) input:hover {
    box-shadow: 1px 3px black;
}
#footerContainer-top-id form div:nth-child(2) input:active {
    box-shadow: -1px -1px black;
}
