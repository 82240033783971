.src_apps_lifeatschool_lifeatschool_life {
    width: 95%;
    height: fit-content;
    box-shadow: 0 0 10px 10px #aeaeae52;
    overflow: hidden;
    overflow: scroll;
    margin: 70px auto;
    padding: 20px;
    position: relative;
}



.src_apps_lifeatschool_lifeatschool_life h1 {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 4em;
    color: rgb(116,188,65);
    -webkit-text-decoration: 0 5px 5px 0 black;
    text-decoration: 0 5px 5px 0 black;
    font-weight: bolder;
    z-index: 2;
    margin: auto;
    position: relative;
    word-wrap: break-word;
}
.src_apps_lifeatschool_lifeatschool_life .teachersContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: scroll;
}
.heightClass {
    height: 500px;
}
.src_apps_lifeatschool_lifeatschool_life .controlHeight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    filter: blur(1px);
    background: black;
    color: white;
}
.src_apps_lifeatschool_lifeatschool_life .controlHeight:hover {
    filter: blur(0);
}

.src_apps_lifeatschool_lifeatschool_life .news_title {
    font-size: 3em;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    line-height: 40px;
}

.src_apps_lifeatschool_lifeatschool_life .news_summary {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.src_apps_lifeatschool_lifeatschool_life .news_author {
    
    display: flex;
    flex-direction: column;
}

.src_apps_lifeatschool_lifeatschool_life .news_author span:nth-child(1) {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: underline;
}
.src_apps_lifeatschool_lifeatschool_life .news_author span:nth-child(2) {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
}

.src_apps_lifeatschool_lifeatschool_life .news_description::selection {
    background-color: black;
    color: white;
}

.src_apps_lifeatschool_lifeatschool_life .news_title::selection {
    background-color: black;
    color: white;
}
.src_apps_lifeatschool_lifeatschool_life .news_summary::selection {
    background-color: black;
    color: white;
}
.src_apps_lifeatschool_lifeatschool_life .news_author span::selection {
    background-color: black;
    color: white;
}

.src_apps_lifeatschool_lifeatschool_life button {
    font-family: 'Montserrat','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
    color: white;
    width: 150px;
    font-size: 12px;
    height: 35px;
    border: 1px solid #74bc41;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    transition: all .3s ease-in;
    background-color: rgb(59, 5, 5);

}