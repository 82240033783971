@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

/*Default css*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background: #fff !important;
    color: black !important;
    font-family: 'Open_Sans', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

@media(min-width: 1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}
nav.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: .32s ease-in-out;
    padding: 0;
    background-color: white;
}

.navbar {
    box-shadow: 0 10px 10px 0 #0000000d;
}

.navbar-brand {
    width: 6em;
    margin-top: 10px;
    margin-bottom: 10px;
}

#basic-navbar-nav.show .navbar-nav{ /*after it has collapsed css*/
    /* flex-direction: row;
    justify-content: space-evenly;
    background-color: rgba(0, 0, 0, 0.277); */
    background-color: #fff;
    position: relative;
    padding-bottom: 20px;
}
.nav-link.active {
    background-color: rgb(116,188,65);
}
@keyframes loadDropDown {
    from {
        transform: translateY(40px);
        opacity: .5;
    }
    to {
        transform: translateY(0);
    }
}
.dropdown-menu.show a {
    font-family: 'OpenSans';
}
.dropdown-menu.show a:hover {
    color: black;
    background-color: rgb(116,188,65);
}
.dropdown-menu.show {
    border: none;
    box-shadow: 0 10px 10px 0 #0000000d;
    animation: loadDropDown .5s ease-in-out 1;
}
@keyframes loadDropDown {
    from {
        transform: translateY(40px);
        opacity: .5;
    }
    to {
        transform: translateY(0);
    }
}
.dropdown-toggle::after {
    border-top: .3em solid rgb(116,188,65);
}
#home {
    color: black;
    font-family: 'OpenSans';
       font-weight: bold;
       font-size: 1.1em;
       transition: .3s all ease-in-out;
}

#programs {
    color: black;
    font-family: 'OpenSans';
    font-weight: bold;
    font-size: 1.1em;
    transition: .3s all ease-in-out;
}

#admissions {
    color: black;
    font-family: 'OpenSans';
    font-weight: bold;
    font-size: 1.1em;
    transition: .3s all ease-in-out;
}

#school {
    color: black;
    font-family: 'OpenSans';
       font-weight: bold;
       font-size: 1.1em;
       transition: .3s all ease-in-out;
}

#studentlife {
    color: black;
    font-family: 'OpenSans';
    font-weight: bold;
    font-size: 1.1em;
    transition: .3s all ease-in-out;
}

#donation {
    color: black;
    font-family: 'OpenSans';
       font-weight: bold;
       font-size: 1.1em;
       transition: .3s all ease-in-out;

}

/* .dropdown-menu.show {
    margin-bottom: 20px;
} */
.dropdown-toggle::after {
    
}

@media (min-width: 992px) {
    
   

   .navbar-nav {
        position: absolute;
        right: 50px;
        width: fit-content;
    }
    
   .navbar-nav .nav-link {
        width: fit-content;
       height: 105px;
       cursor: pointer;
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 10px;
       font-family: 'OpenSans';
       font-weight: bold;
       font-size: 1.1em;
       transition: .3s all ease-in-out;
       color: black;
    }
   .navbar-nav .nav-link.active {
        background-color: rgb(116,188,65);
    }
   
   .navbar-nav .nav-link:hover {
        color: black;
        background-color: rgb(116,188,65);
    }
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu.show {
        border: none;
        margin-left: -50px;
        box-shadow: 0 10px 10px 0 #0000000d;
        animation: loadDropDown .5s ease-in-out 1;
    }
    .dropdown-menu.show a {
        font-family: 'OpenSans';
    }
    .dropdown-menu.show a:hover {
        color: black;
        background-color: rgb(116,188,65);
    }

    @keyframes loadDropDown {
        from {
            transform: translateY(40px);
            opacity: .5;
        }
        to {
            transform: translateY(0);
        }
    }
}


nav.navbar .navbar-toggler {
    border-radius: 0;
    box-shadow: 3px 3px rgb(116,188,65);
}

nav.navbar .navbar-toggler:hover {
    box-shadow: 1px 1px rgb(116,188,65);
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
}

nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid rgb(116,188,65);
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: rgb(116,188,65);
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: rgb(116,188,65);
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: rgb(116,188,65);;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}