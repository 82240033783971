.src_apps_lifeatschool_lifeatschool_eventatschool {
    width: 70%;
    height: fit-content;
    padding-top: 80px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.src_apps_lifeatschool_lifeatschool_eventatschool .eventcontainer {
    width: 80%;
    height: fit-content;
    box-shadow: 0 0 10px 10px rgba(167, 156, 156, 0.084);
    margin-top: 60px;
    padding-bottom: 20px;
}

.src_apps_lifeatschool_lifeatschool_eventatschool .eventcontainer div:nth-child(1){
    border-bottom: 2px solid rgba(167, 156, 156, 0.277);
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
    font-size: larger;
}

.src_apps_lifeatschool_lifeatschool_eventatschool .eventcontainer .eventTime {
    width: 80%;
    margin: 10px auto;
    text-align: center;
    border-bottom: 2px solid rgba(167, 156, 156, 0.277);
}

.src_apps_lifeatschool_lifeatschool_eventatschool .eventcontainer .eventDescription {
    width: 80%;
    margin: 10px auto;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.src_apps_lifeatschool_lifeatschool_eventatschool button {
    font-family: 'Montserrat','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
    color: white;
    width: 150px;
    font-size: 12px;
    height: 35px;
    border: 1px solid #74bc41;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    transition: all .3s ease-in;
    background-color: rgb(59, 5, 5);
}