@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}

.swiper-container {
  width: 480px;
}



@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@media (max-width: 992px) {
    /*desktop design*/
    .bannerFourContainer {
        width: 100%;
        height: fit-content;
        background: url('./assets/DSC_6854.JPG') no-repeat;
        background-size: cover;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .bannerFourContainer::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 80%);
    }
    .bannerFourContainer h1 {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: white;
        font-weight: bolder;
        z-index: 2;
        margin-top: 50px;
        position: relative;
    }
    .bannerFourContainer h1::before {
        content: '';
        position: absolute;
        left: 48.5%;
        bottom: -10px;
        width: 60px;
        height: 2px;
        background-color: rgb(116,188,65);
    }



    /*slide css*/

.contentSlider .swiper {
    width: 90%;
    padding: 0 50px 0 50px;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.12);
    position: relative;
}

  
  
  .contentSlider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    overflow: hidden;
    position: relative;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    /*size*/
    height: 500px;
  }

  .contentSlider .swiper-slide div {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .contentSlider .swiper-slide div img {
    width: 100%;
    height: 80%;
    background-size: contain;
    object-fit: cover;
  }

  .contentSlider .swiper-slide div h4 {
    position: absolute;
    top: 266px;
    right: 10px;
    z-index: 1;
    background-color: rgb(116,188,65);
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    box-shadow: -1px -5px 20px 0px black;
    font-family: 'OpenSans';
    font-size: 1em;
  }

  .contentSlider .swiper-slide div h3 {
    width: 100%;
    height: fit-content;
    margin: 15px 0 0 15px;
    padding: 5px;
    text-align: left;
    font-family: 'OpenSans';
    font-weight: bolder;
    font-size: 1.2em;
  }
  .contentSlider .swiper-slide div p {
    width: 100%;
    height: fit-content;
    padding: 5px 6px 5px 15px;
    word-wrap: break-word;
    text-align: left;
    font-family: 'OpenSans';
    font-weight: lighter;
    font-size: 0.7em;
  }
  .contentSlider .swiper-slide div div {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid black;
    align-self: center;
    position: absolute;
    bottom: 10px;
    left: 5%;
  }
}


@media (min-width: 992px) {
    /*desktop design*/
    .bannerFourContainer {
        width: 100%;
        height: fit-content;
        background: url('./assets/DSC_6854.JPG') no-repeat;
        background-size: cover;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .bannerFourContainer::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 80%);
    }
    .bannerFourContainer h1 {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: white;
        font-weight: bolder;
        z-index: 2;
        margin-top: 50px;
        position: relative;
    }
    .bannerFourContainer h1::before {
        content: '';
        position: absolute;
        left: 48.5%;
        bottom: -10px;
        width: 60px;
        height: 2px;
        background-color: rgb(116,188,65);
    }



    /*slide css*/

.contentSlider .swiper {
    width: 90%;
    padding: 0 50px 0 50px;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.12);
    position: relative;
}

  
  
  .contentSlider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    overflow: hidden;
    position: relative;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    /*size*/
    height: 500px;
  }

  .contentSlider .swiper-slide div {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .contentSlider .swiper-slide div img {
    width: 100%;
    height: 300px;
    background-size: contain;
    object-fit: cover;
  }

  .contentSlider .swiper-slide div h4 {
    position: absolute;
    top: 266px;
    right: 10px;
    z-index: 1;
    background-color: rgb(116,188,65);
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    box-shadow: -1px -5px 20px 0px black;
    font-family: 'OpenSans';
    font-size: 1em;
  }

  .contentSlider .swiper-slide div h3 {
    width: 100%;
    height: fit-content;
    margin: 15px 0 0 15px;
    padding: 5px;
    text-align: left;
    font-family: 'OpenSans';
    font-weight: bolder;
    font-size: 1.2em;
  }
  .contentSlider .swiper-slide div p {
    width: 100%;
    height: fit-content;
    padding: 5px 6px 5px 15px;
    word-wrap: break-word;
    text-align: left;
    font-family: 'OpenSans';
    font-weight: lighter;
    font-size: 1em;
  }
  .contentSlider .swiper-slide div div {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid black;
    align-self: center;
    position: absolute;
    bottom: 10px;
    left: 5%;
  }
}