
.src_apps_lifeatschool_lifeatschool {
    width: 95%;
    height: fit-content;
    overflow-x: hidden;
    padding: 50px;
}

.src_apps_lifeatschool_lifeatschool .nav-pills .nav-link.active {
    background-color: black;
    color: white;
}

.src_apps_lifeatschool_lifeatschool .nav-pills .nav-link {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12px;
    color: black;
    font-weight: bolder;
    border-radius: 0;
    width: 250px;
}

.src_apps_lifeatschool_lifeatschool .news_menu {
    width: 220px;
    overflow: scroll;
}

.size {
    height: 100px;
}

.src_apps_lifeatschool_lifeatschool .news_menu ul li {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: lighter;
    list-style: none;
    background-color: antiquewhite;
    margin: 5px;
    cursor: pointer;
}
.src_apps_lifeatschool_lifeatschool .news_menu ul li:hover {
    font-weight: bolder;
}