@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}


@font-face {
    font-family: OpenSans;
    src: url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@media (min-width: 992px) {
    /*desktop design*/
    .galleryContainer {
        width: 100%;
        height: fit-content;
        padding-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .galleryContainer .galleryActionCall {
        width: 30%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 20px;
    }
    .galleryContainer .galleryActionCall h1 {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: rgb(116,188,65);
        text-decoration: 0 5px 5px 0 black;
        font-weight: bolder;
        z-index: 2;
        margin: auto;
        position: relative;
        word-wrap: break-word;
    }
    .galleryContainer .galleryActionCall p {
        color: black;
    z-index: 2;
    width: 100%;
    height: fit-content;
    text-align: center;
    font-family: 'OpenSans';
    font-size: 1em;
    text-decoration: 0 5px 5px 0 black;
    font-weight: 400;
    margin: 20px auto;
    }
    .galleryContainer .galleryActionCall button {
        z-index: 2;
    width: 250px;
    border-radius: 50px;
    margin: 50px auto;
    background-color: rgb(116,188,65);
    padding: 10px;
    font-family: 'OpenSans';
    font-size: 1.5em;
    font-weight: bolder;
    color: white;
    transition: all .3s ease-in;
    }
    .galleryContainer .galleryActionCall button:hover {
        box-shadow: 0 5px 5px 0 black;
    }
    .galleryContainer .lg-react-element  {
        width: 60%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .galleryContainer .lg-react-element a {
       position: relative;
       width: 250px;
        height: 150px;
        margin: 5px;
    }
    .galleryContainer .lg-react-element a span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(116, 188, 65, 0.158);
        z-index: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        display: none;
     }
     .galleryContainer .lg-react-element a:hover span {
        display: block;
     }
     .galleryContainer .lg-react-element a span img {
        width: 50px;
        height: 50px;
        margin-top: 20%;
     }
    .galleryContainer .lg-react-element a img {
        width: 250px;
        height: 150px;
        object-fit: cover;
    }
    
    /*onclick*/
    .lg-backdrop.in {
        opacity: .8;
    }
    .lg-container .lg-outer {
        top: 100px;
    }
    .lg-components {
        bottom: 100px;
    }
    .lg-sub-html {
        color: white;
    font-size: 1em;
    width: fit-content;
    margin: auto;
    background: black;
    }
}

@media (max-width: 992px) {
    .galleryContainer {
        width: 100%;
        height: fit-content;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .galleryContainer .galleryActionCall {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 50px;
    }
    .galleryContainer .galleryActionCall h1 {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-family: 'OpenSans';
        font-size: 4em;
        color: rgb(116,188,65);
        text-decoration: 0 5px 5px 0 black;
        font-weight: bolder;
        z-index: 2;
        margin: auto;
        position: relative;
        word-wrap: break-word;
    }
    .galleryContainer .galleryActionCall p {
        color: black;
    z-index: 2;
    width: 98%;
    height: fit-content;
    text-align: center;
    font-family: 'OpenSans';
    font-size: 1em;
    text-decoration: 0 5px 5px 0 black;
    font-weight: 400;
    margin: 20px auto;
    }
    .galleryContainer .galleryActionCall button {
        z-index: 2;
    width: 250px;
    border-radius: 50px;
    margin: 50px auto;
    background-color: rgb(116,188,65);
    padding: 10px;
    font-family: 'OpenSans';
    font-size: 1.5em;
    font-weight: bolder;
    color: white;
    transition: all .3s ease-in;
    }
    .galleryContainer .galleryActionCall button:hover {
        box-shadow: 0 5px 5px 0 black;
    }
    .galleryContainer .lg-react-element  {
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr;
    }
    .galleryContainer .lg-react-element a img {
        width: 80%;
        height: 300px;
        object-fit: cover;
        margin: 5px auto;
    }

    .galleryContainer .lg-react-element a:nth-child(4) {
        display: none;
    }
    .galleryContainer .lg-react-element a:nth-child(5) {
        display: none;
    }
    .galleryContainer .lg-react-element a:nth-child(6) {
        display: none;
    }
    .galleryContainer .lg-react-element a:nth-child(7) {
        display: none;
    }
    .galleryContainer .lg-react-element a:nth-child(8) {
        display: none;
    }
    .galleryContainer .lg-react-element a:nth-child(9) {
        display: none;
    }
    .galleryContainer .lg-react-element a {
        position: relative;
        width: 80%;
        height: 300px;
        margin: 5px auto;
     }
     .galleryContainer .lg-react-element a span{
         position: absolute;
         top: 5px;
         left: 10%;
         width: 80%;
         height: 100%;
         background-color: rgba(116, 188, 65, 0.158);
         z-index: 100;
         display: flex;
         flex-direction: row;
         justify-content: center;
         display: none;
      }
      .galleryContainer .lg-react-element a:hover span {
         display: block;
      }
      .galleryContainer .lg-react-element a span img {
         width: 50px;
         height: 50px;
         margin-top: 35%;
      }
    /*mobile design*/
    .lg-backdrop.in {
        opacity: .8;
    }
    .lg-container .lg-outer {
        top: 100px;
    }
    .lg-components {
        bottom: 100px;
    }
    .lg-sub-html {
        color: white;
    font-size: 1em;
    width: fit-content;
    margin: auto;
    background: black;
    }
}